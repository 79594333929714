<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                id="name"
                v-model="searchName"
                placeholder="Search Name"
                class="searchName mr-1"
                trim
              >
              </b-form-input>
              
              <multiselect
                v-model="searchQuery"
                tag-placeholder="Add this as new tag"
                placeholder="Search or add a tag"
                :hide-selected="true"
                :options="options"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
              ></multiselect>
              <b-button
                class="mr-1 ml-1"
                variant="primary"
                @click="addCandidate"
              >
                <span class="text-nowrap">Add Candidate to List</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchCandidates"
        :sort-desc.sync="isSortDirDesc"
        responsive="sm"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        busy.sync="isBusy"
        empty-text="No matching records found"
      >
        <template slot="empty">
          <div v-if="loading" class="text-center">
            <b-spinner variant="primary" label="Loading..."></b-spinner>
          </div>
        </template>
        <!-- Column: User -->
        <template #cell(fullName)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :class="'border_avatar_' + data.item.candidateProfileTypeId"
                :src="data.item.avatarFileUrl"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
              />
            </template>
            <b-link class="font-weight-bold d-block text-nowrap">
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">{{ data.item.currentCity }}</small>
            <small class="text-muted">{{
              birthDate(data.item.birthDate)
            }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.statusName)}`"
            class="text-capitalize"
          >
            {{ data.item.statusName }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-form-group>
            <input
              type="checkbox"
               size="lg"
              :id="data.item.id"
              v-model="data.item.selected"
              @change="selected(data.item.id, data.item.selected)"
            />
          </b-form-group>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              v-if="!loading"
              @change="fetchItems"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center"
          >
            <span class="text-muted">
              {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} Candidate</span
            >
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<style>
.border_avatar_1 {
  border: 2px solid rgb(58, 250, 0) !important;
}
.border_avatar_2 {
  border: 2px solid rgb(250, 0, 0) !important;
}
.border_avatar_3 {
  border: 2px solid rgb(0, 4, 250) !important;
}
.border_avatar_4 {
  border: 2px solid rgb(146, 146, 146) !important;
}
.searchName {
  width: 300px;
}
</style>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  VBModal,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "./UsersListFilters.vue";
import useUsersList from "./useUsersList";
import userStoreModule from "../userStoreModule";
import UserListAddNew from "./UserListAddNew.vue";
import Multiselect from "vue-multiselect";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";
//import Ripple from 'vue-ripple-directive'

export default {
  //   directives: {
  //   Ripple,
  // },
  components: {
    UsersListFilters,
    UserListAddNew,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    //BDropdown,
    // BDropdownItem,
    BPagination,
    ToastificationContent,
    vSelect,
    Multiselect,
    BSpinner,
    BFormGroup,
    BFormCheckbox,
  },

  data() {
    return {
      options: [],
      ren: true,
      ccurrentPage: 7,
      candidateList: [],
    };
  },
  mounted() {},
  methods: {
    addCandidate() {
     
      store
        .dispatch("app-user/addInterview", {
          id:0,
          jobId: Number(router.currentRoute.params.id),
          candidateId:this.candidateList
        })
        .then((response) => {
          
          router.push(`/apps/companies/candidate/${router.currentRoute.params.id}`);
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: value,
              },
            });
          }
        });
    },
    selected(value, isselect) {
      if (isselect) {

     store
        .dispatch("app-user/fetchCheckCandidate", {id:value})
        .then((response) => {
          if(response.data.data.isUse)
          {
           this.candidateList.push(value);
          }else{
              this.$toast({
              component: ToastificationContent,
              props: {
                title: "The Candidate has in progress",
                icon: "AlertTriangleIcon",
                variant: "warning",
                text: `Company Name:${response.data.data.companyName} Positon:${response.data.data.jobTitle}`
              },
            });
          }
          
        })
      } else {
        this.candidateList.splice(this.candidateList.indexOf(value),1);
      }
    },
    addTag(newTag) {
      this.refetchData();
      this.options.push(newTag);
      this.searchQuery.push(newTag);
      this.sortBy = "title";
    },
    fetchItems(value) {
      store.commit("appConfig/UPDATE_CURRENTPAGE", value);
    },
    modalId(id) {
      return "modal" + id;
    },
    deleteData(id) {
      this.$bvModal.hide("modal" + id);
      store
        .dispatch("app-user/delete", {
          id: id,
        })
        .then((response) => {
          this.refetchData();
          //router.go("/apps/list");
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: value,
              },
            });
          }
        });
    },
    birthDate(birthDate) {
      if (birthDate !== "" && birthDate !== null) {
        var dob = "19800810";
        var year = Number(birthDate.substr(0, 4));
        var month = Number(birthDate.substr(4, 2)) - 1;
        var day = Number(birthDate.substr(6, 2));
        var today = new Date();
        var age = today.getFullYear() - year;
        if (
          today.getMonth() < month ||
          (today.getMonth() == month && today.getDate() < day)
        ) {
          age--;
        }
        return " - " + age;
      } else return " ";
    },
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    const toast = useToast();
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    const {
      fetchCandidates,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchName,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      loading,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      
    } = useUsersList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      fetchCandidates,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchName,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      loading,
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
